<div class="sidepanel-container">
  <div class="sidepanel-full">
    <div>{{ "component.multipleAttack.header" | translate }}</div>
    <div *ngIf="distance">
      <nz-statistic
        [nzValue]="distance"
        nzTitle="{{ 'component.multipleAttack.distance' | translate }}"
      ></nz-statistic>
    </div>
  </div>
  <div *ngIf="data.mode === 'default'" class="sidepanel-full">
    <nz-button-group>
      <button
        nz-button
        nzType="primary"
        #copyButton
        (click)="copy(); copyButton.nzType = null"
      >
        {{ "component.multipleAttack.copy" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="remove()" nzDanger>
        {{ "component.multipleAttack.remove" | translate }}
      </button>
    </nz-button-group>
  </div>
  <sidepanel-area
    header="{{ 'component.multipleAttack.selection' | translate }}"
  >
    <nz-tag *ngFor="let ab of items" [nzColor]="getColor(ab)" nzMode="default">
      <span>
        <i nz-icon nzType="clock-circle" theme="outline"></i>
        <span class="abilityTime">{{ formatDate(ab.start) }}</span>
        <span class="abilityName">{{ ab.attack.name }}</span>
      </span>
    </nz-tag>
  </sidepanel-area>
  <ng-template #saveSettingsTpl>
    <button nz-button [nzSize]="'small'" nzType="primary"
      (click)="$event.stopPropagation();$event.preventDefault();saveColor();">{{'component.singleAbility.save' | translate}}</button>
  </ng-template>
  <sidepanel-area
    header="{{ 'component.multipleAttack.setColor' | translate }}"
    [extra]="saveSettingsTpl"
  >
    <nz-select
      nzPlaceHolder="{{ 'dialog.bossAttack.color' | translate }}"
      nzAllowClear
      [(ngModel)]="color"
    >
      <nz-option
        *ngFor="let option of colors"
        [nzLabel]="option"
        [nzValue]="option"
      ></nz-option>
    </nz-select>
  </sidepanel-area>
</div>
