<div
  mat-dialog-content
  style="height: 600px; min-height: 600px; overflow: hidden"
>
  <nz-layout>
    <nz-sider style="overflow: auto; height: 600px" [nzWidth]="350">
      <nz-spin
        nzTip="{{ 'system.loading' | translate }}"
        [nzSpinning]="isSpinning"
        [nzDelay]="100"
      >
        <nz-layout style="height: 600px; min-width: 350px">
          <nz-header style="padding: 3px">
            <nz-input-group
              [nzPrefix]="prefixTemplate1"
              [nzSuffix]="suffixTemplate1"
            >
              <input
                nz-input
                placeholder="{{
                  'dialog.bossTemplates.bossSearch' | translate
                }}"
                [(ngModel)]="searchString"
                (ngModelChange)="onSearchChange($event)"
              />
            </nz-input-group>
            <ng-template #prefixTemplate1>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <ng-template #suffixTemplate1>
              <i
                nz-icon
                nzType="close-circle"
                (click)="clear()"
                *ngIf="searchString"
              ></i>
            </ng-template>
          </nz-header>
          <nz-content customScroll style="overflow: auto">
            <ul nz-menu [nzMode]="'inline'" style="min-height: 100%">
              <li
                nz-submenu
                *ngFor="let zone of filteredZones"
                [nzOpen]="!!searchString || zone.id === selectedZone"
              >
                <span title
                  ><img
                    class="boss-icon"
                    style="margin-right: 5px"
                    [src]="getZoneIcon(zone.id)"
                  />{{ zone.name }}</span
                >
                <ul>
                  <li
                    nz-menu-item
                    *ngFor="let encounter of filterEncounters(zone.encounters)"
                    [nzSelected]="
                      selectedEncounter && selectedEncounter.id === encounter.id
                    "
                    (click)="onEncounterSelected(zone.id, encounter)"
                  >
                    <img
                      class="boss-icon"
                      style="margin-right: 5px"
                      [src]="getBossIcon(encounter.id)"
                    />
                    {{ encounter.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </nz-content>
        </nz-layout>
      </nz-spin>
    </nz-sider>

    <nz-layout style="min-height: 600px">
      <nz-header style="padding: 3px">
        <nz-input-group
          nzSpan="8"
          [nzPrefix]="prefixTemplate2"
          [nzSuffix]="suffixTemplate2"
        >
          <input
            nz-input
            placeholder="{{
              'dialog.bossTemplates.searchTemplate' | translate
            }}"
            [(ngModel)]="searchFightString"
            (ngModelChange)="onSearchFightChange($event)"
          />
        </nz-input-group>
        <ng-template #prefixTemplate2
          ><i nz-icon nzType="search"></i
        ></ng-template>
        <ng-template #suffixTemplate2
          ><i
            nz-icon
            nzType="close-circle"
            (click)="clearTemplates()"
            *ngIf="searchFightString"
          ></i
        ></ng-template>
      </nz-header>
      <nz-content
        style="
          margin: 4px 2px 2px 4px;
          overflow: auto;
          min-height: 524px;
          height: 524px;
        "
        customScroll
      >
        <as-split
          direction="vertical"
          unit="percent"
          [gutterSize]="5"
          [restrictMove]="true"
          style="min-height: 524px; height: 524px"
        >
          <as-split-area [size]="50" [minSize]="10" [maxSize]="50">
            <nz-spin
              nzTip="{{ 'system.loading' | translate }}"
              [nzSpinning]="isListLoading"
              [nzDelay]="100"
              style="min-width: 100%; min-height: 100%; height: 100%"
            >
              <div
                #listContainer
                [class.fullSize]="true"
                style="overflow: auto"
                customScroll
              >
                <nz-list
                  [nzDataSource]="filteredTemplates"
                  nzSize="small"
                  [ngClass]="{ loadList: true }"
                  [class.fullSize]="true"
                  [nzBordered]="true"
                  [nzNoResult]="nothing"
                  [nzRenderItem]="item"
                >
                  <ng-template #nothing>
                    <nz-empty></nz-empty>
                  </ng-template>
                  <ng-template #item let-item>
                    <nz-list-item
                      [class.selected]="
                        selectedTemplate &&
                        selectedTemplate.id.toLowerCase() ===
                          item.id.toLowerCase()
                      "
                      [nzActions]="[removeAction]"
                    >
                      <nz-list-item-meta
                        [nzTitle]="nzTitle"
                        (click)="select(item)"
                      >
                        <ng-template #nzTitle>
                          <span
                            [class.selected]="
                              selectedTemplate &&
                              selectedTemplate.id.toLowerCase() ===
                                item.id.toLowerCase()
                            "
                          >
                            {{ item.name }}
                          </span>
                        </ng-template>
                      </nz-list-item-meta>
                      <ng-template #removeAction>
                        <a
                          *ngIf="item.canRemove"
                          nz-popconfirm
                          nzPopconfirmTitle="{{
                            'dialog.bossTemplates.areYouSureDeleteBossTemplate'
                              | translate
                          }}"
                          nzPopconfirmPlacement="left"
                          (nzOnConfirm)="remove(item, $event)"
                        >
                          <i
                            nz-icon
                            nzType="delete"
                            class="action-icon"
                            nzTheme="twotone"
                          ></i>
                        </a>
                      </ng-template>
                    </nz-list-item>
                  </ng-template>
                </nz-list>
              </div>
            </nz-spin>
          </as-split-area>
          <as-split-area [size]="50" [minSize]="50">
            <nz-spin
              nzTip="{{ 'system.loading' | translate }}"
              [nzSpinning]="isTimelineLoading"
              [nzDelay]="100"
              style="
                width: 100%;
                height: 100%;
                min-width: 100%;
                min-height: 100%;
              "
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  min-width: 100%;
                  min-height: 100%;
                  display: block;
                "
              >
                <div #timeline class="boss-timeline fullSize"></div>
              </div>
            </nz-spin>
          </as-split-area>
        </as-split>
      </nz-content>
    </nz-layout>
  </nz-layout>
  <div *nzModalFooter>
    <button nz-button (click)="dialogRef.destroy()">
      {{ "dialog.cancel" | translate }}
    </button>
    <button
      nz-button
      nzType="primary"
      *ngIf="!!selectedTemplate"
      (click)="load()"
    >
      {{ "dialog.load" | translate }}
    </button>
    <button
      nzType="primary"
      nz-button
      *ngIf="data && data.needSave && data.boss"
      [nzDanger]="!authService.authenticated"
      (click)="saveAsNew()"
    >
      {{ "dialog.saveAsNew" | translate }}
    </button>
    <button
      nz-button
      nzType="primary"
      *ngIf="
        data &&
        data.needSave &&
        selectedEncounter &&
        ((data.boss && data.boss.userName === authService.username) ||
          !data.boss)
      "
      [nzDanger]="!authService.authenticated"
      (click)="save()"
    >
      {{ "dialog.save" | translate }}
    </button>
  </div>
</div>
