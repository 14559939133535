<nz-page-header class="noselect toolbar">
  <nz-page-header-title>
    <span style="margin-right: 10px;margin-left: 15px !important;" *ngIf="showHome">
      <button nz-button (click)="onHome()" nz-tooltip nzTooltipTitle="{{'component.toolbar.home-tooltip' | translate}}">
        <i nz-icon theme="twotone" nzType="home"></i>
      </button>
    </span>
    <span>
      <ng-content></ng-content>
    </span>
  </nz-page-header-title>
  <nz-page-header-extra>
    <ng-content select="[pre-right]"></ng-content>
    <ng-content select="[right]"></ng-content>
    <span>
      <button nz-button nzShape="circle" nzType="primary" aria-label="Settings" nz-tooltip nzTooltipTitle="{{'component.toolbar.settings-tooltip' | translate}}"
        (click)="openSettings()" style="margin-left: 10px">
        <i nz-icon nzType="setting" aria-label="Settings"></i>
      </button>
    </span>
    <span>
      <button nz-button nzType="primary" aria-label="Github" nzShape="circle" nz-tooltip
        nzTooltipTitle="{{'component.toolbar.leaveFeedback' | translate}}" (click)="gotoGithub()" style="margin-left: 10px">
        <i nz-icon nzType="github" theme="outline"></i>
      </button>
    </span>
    <span>
      <button nz-button nzShape="circle" aria-label="Change Theme" nz-tooltip nzTooltipTitle="{{'component.toolbar.changeTheme' | translate}}"
        (click)="changeTheme()" style="margin-left: 10px">
        <span *ngIf="darkTheme">🌙</span>
        <span *ngIf="!darkTheme">☀️</span>
      </button>
    </span>
    <span>
      <button nz-dropdown nz-button [nzDropdownMenu]="langMenu" aria-label="Language" [nzTrigger]="'click'" nzType="dashed"
        nzShape="circle" style="margin-left: 10px">
        {{currentLang}}
      </button>
      <nz-dropdown-menu #langMenu="nzDropdownMenu">
        <ul nz-menu class="noselect">
          <li nz-menu-item (click)="setLang('en')">EN</li>
          <li nz-menu-item (click)="setLang('fr')">FR</li>
          <li nz-menu-item (click)="setLang('de')">DE</li>
          <li nz-menu-item (click)="setLang('ja')">JP</li>
          <li nz-menu-item (click)="setLang('cn')">CN</li>
        </ul>
      </nz-dropdown-menu>
    </span>
    <span>
      <button nz-dropdown nz-button aria-label="Account" [nzDropdownMenu]="accountMenu" nz-tooltip
        [nzTooltipTrigger]="'click'" nzShape="circle" nzTooltipTitle="{{'component.toolbar.account' | translate}}"
        [nzDanger]="!authenticationService.authenticated">
        <i nz-icon nzType="user" aria-label="Account"></i>
      </button>
      <nz-dropdown-menu #accountMenu="nzDropdownMenu">
        <ul nz-menu class="noselect">
          <li nz-menu-item (click)="login()" *ngIf="!authenticationService.authenticated">
            {{'component.toolbar.signIn' | translate}}
          </li>
          <li nz-menu-item (click)="register()" *ngIf="!authenticationService.authenticated">
            {{'component.toolbar.signUp' | translate}}
          </li>
          <li nz-menu-item *ngIf="authenticationService.authenticated">
            {{'component.toolbar.loggedAs' | translate}} {{authenticationService.username}}
          </li>
          <li nz-menu-item (click)="logout()" *ngIf="authenticationService.authenticated">
            {{'component.toolbar.signOut' | translate}}
          </li>
        </ul>
      </nz-dropdown-menu>
    </span>
    <span>
      <button nz-dropdown nz-button [nzDropdownMenu]="helpMenu" aria-label="Help" [nzTrigger]="'click'" nzType="dashed"
        nzShape="circle" style="margin-left: 10px">
        <i nz-icon nzType="question" aria-label="Help"></i>
      </button>
      <nz-dropdown-menu #helpMenu="nzDropdownMenu">
        <ul nz-menu class="noselect">
          <li nz-menu-item (click)="showWhatsNewInt()">{{'component.toolbar.whatsNew' | translate}}</li>
          <li nz-menu-item (click)="showHelp()">{{'component.toolbar.help' | translate}}</li>
        </ul>
      </nz-dropdown-menu>
    </span>
  </nz-page-header-extra>
</nz-page-header>
