<div style="min-height: 560px; height: 560px; overflow: hidden">
  <nz-layout>
    <nz-sider style="overflow: auto; height: 100vh" customScroll>
      <ul nz-menu [nzMode]="'inline'">
        <li nz-menu-item [nzSelected]="true" (click)="showSection('basics')">
          Basics
        </li>
        <!-- <li nz-menu-item (click)="showSection('Main menu')"> Main menu </li>
        <li nz-menu-item (click)="showSection('Keyboard')"> Keyboard </li>
        <li nz-menu-item (click)="showSection('Tools')"> Tools </li>
        <li nz-menu-item (click)="showSection('View')"> View </li>
        <li nz-menu-item (click)="showSection('Context menus')"> Context menus </li> -->
      </ul>
    </nz-sider>
    <nz-layout style="height: 560px">
      <nz-content
        style="margin: 24px 2px 2px 10px; overflow: auto"
        customScroll
      >
        <markdown [src]="'assets/docs/'+section+'.md'"></markdown>
      </nz-content>
    </nz-layout>
  </nz-layout>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="this.dialogRef.destroy()">
      {{ "dialog.cancel" | translate }}
    </button>
  </div>
</div>
