<form nz-form>
  <nz-form-item>
    <nz-form-control nzSpan="4">
      <span style="margin-left: 10px" *ngIf="selectedValue">
        <tableviewoptions
          [settings]="options"
          (changed)="optionsChanged($event)"
        ></tableviewoptions>
      </span>
    </nz-form-control>
    <nz-form-control nzSpan="8">
      <nz-select
        nzPlaceHolder="Select Template"
        name="exportTemplatesList"
        [(ngModel)]="selectedValue"
        (ngModelChange)="show()"
        nzSize="small"
      >
        <nz-option
          *ngFor="let template of templates"
          [nzValue]="template.name"
          [nzLabel]="template.name"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>

<div mat-dialog-content style="height: 650px">
  <nz-table
    #table
    [nzData]="set.rows"
    [nzBordered]="true"
    [nzShowPagination]="false"
    [nzPageSize]="pagesize"
    [nzScroll]="{ y: '600px' }"
    nzSize="small"
  >
    <thead>
      <tr *ngFor="let h of set.headers">
        <th
          *ngFor="let column of h; trackBy: trackByName"
          [nzAlign]="column.align || 'center'"
          [nzFilterMultiple]="true"
          [nzFilters]="column.listOfFilter"
          [nzWidth]="column.width"
          [ngStyle]="{ cursor: column.cursor }"
          [style.background-color]="column.backgroundColor"
          [colSpan]="column.colSpan"
          [rowSpan]="column.rowSpan"
          (nzFilterChange)="filterChange($event, column.name)"
        >
          <img
            *ngIf="column.icon"
            class="abilityIcon"
            style="margin-right: 2px; margin-top: -3px"
            [style.width]="(column.iconSize || 24) + 'px'"
            [style.height]="(column.iconSize || 24) + 'px'"
            [src]="column.icon"
          />
          <span *ngIf="!column.icon || (column.icon && showText)">{{
            column.text
          }}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of filtered">
        <td
          *ngFor="let cell of row.cells"
          [nzAlign]="cell.align || 'left'"
          [ngStyle]="{
            'background-color':
              (cell.colorFn && cell.colorFn(row.filterData)) || ''
          }"
        >
          <cell
            [input]="cell"
            [showText]="showText"
            [showIcon]="showIcon"
            [showOffset]="showOffset"
            [showTarget]="showTarget"
            [iconSize]="iconSize"
          ></cell>
        </td>
      </tr>
    </tbody>
  </nz-table>
</div>

<div *nzModalFooter>
  <button nz-button (click)="onClose()" nzType="primary" cdkFocusInitial>
    {{ "dialog.cancel" | translate }}
  </button>
</div>
