<div class="settings-view">
  <div>
    <label nz-checkbox [(ngModel)]="showDowntimesInPartyArea">{{"view.phasesInPartyArea" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="buffmap">{{"view.damageBuffsHeatmap" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="ogcdAsPoints">{{"view.ogcdAsPoints" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="verticalBossAttacks">{{"view.verticalBossAttacks" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="compactView">{{"view.compactView" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="highlightLoaded">{{"view.highlightLoaded" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="abilityAvailablity">{{"view.abiliesAvailability" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="colorfulDurations">{{"view.colorfullDurations" | translate}}</label>
  </div>
  <div>
    <label nz-checkbox [(ngModel)]="statusesAsRows">{{"view.statusesAsRows" | translate}}</label>
  </div>
</div>
