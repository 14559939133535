<toolbar>
  <span class="toolbar-title">Fight Time Line</span>
</toolbar>
<div class="main-content noselect"
  style="text-align: center; height: calc(100% - 54px); max-height: calc(100% - 54px); overflow: auto" customScroll>
  <div style="width: 90%; min-width: 800px; max-width: 1400px; margin-top: 15px; display: inline-block">
    <div style="width: 90%; min-width: 800px; max-width: 1400px; margin-top: 15px; display: inline-block">
      <nz-card [nzTitle]="header">
        <ng-template #header>
          <div class="home-card">
            {{"home.whatWouldYouLikeToDo" | translate}}
          </div>
        </ng-template>
        <div nz-row rowHeight="90%">
          <div nz-col nzSpan="16">
            <div class="home-column">
              <div class="card-subtitle">
                {{"home.openRecent" | translate}}
              </div>
              <div customScroll class="recentList">
                <app-recent-list header="{{ 'home.pinned' | translate}}" [data]="container.pinned" (pinChanged)="pinChanged($event)"
                  (deleted)="delete($event)"></app-recent-list>
                <app-recent-list header="{{'home.activities' | translate}}" [data]="container.nonpinned" (pinChanged)="pinChanged($event)"
                  (deleted)="delete($event)"></app-recent-list>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="8" class="home-actions">
            <div class="home-column">
              <div class="card-subtitle">
                {{"home.getStarted" | translate}}
              </div>
              <div>
                <button nz-button class="action-button" (click)="showHelp()">{{"home.openHelp" | translate}}</button>
              </div>
              <div *ngIf="gameService.showImport">
                <button nz-button color="primary" class="action-button"
                  (click)="importFromFFLogs()">{{"home.importFFLogs" | translate}}</button>
                <div style="text-align: right;margin-top: 3px;margin-right: 23px;">
                  <a target="_blank" rel="noopener" [href]="'https://www.fflogs.com/'+ fflogsExtraPath"
                    style="border-bottom-style: dotted;border-bottom-width: thin;">{{"home.openFFLogs" | translate}}</a>
                </div>
              </div>
              <div>
                <button nz-button [nzDanger]="!authenticationService.authenticated" class="action-button"
                  (click)="load()">{{"home.load" | translate}}</button>
              </div>
              <div>
                <button nz-button class="action-button" (click)="bossTemplates()">{{"home.bossTemplates" |
                  translate}}</button>
              </div>
              <div>
                <button nz-button color="primary" class="action-button" i18n (click)="new()">{{"home.startNew" |
                  translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </nz-card>
    </div>
    <!-- <div style="width: 90%; min-width: 800px; max-width: 1400px; margin-top: 15px; display: inline-block">
      <disqus [identifier]="'/'"></disqus>
    </div> -->
  </div>
</div>
