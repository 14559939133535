<ng-container *ngFor="let d of input.items">
  <label
    *ngIf="isExportItemCheckbox(d)"
    nz-checkbox
    [nzId]="d.id"
    [ngModel]="d.checked"
    [nzDisabled]="d.disabled"
    (nzCheckedChange)="d.onChanged(d.id, $event)"
  >
  </label>
  <nz-tag
    *ngIf="
      isExportItemCommon(d) && d.visible && (d.text || (!d.text && d.icon))
    "
    cdkDrag
    (cdkDragStarted)="onDragStart(d.refId, $event)"
    [cdkDragData]="d"
    [cdkDragDisabled]="!(showIcon && d.icon)"
    [nzColor]="d.color"
    [nz-tooltip]="!!d.tooltip && tt"
    [nzTooltipPlacement]="'right'"
    [class.clone]="d.clone"
    [class.tableview]="true"
    [style.lineHeight]="(d.ignoreIconScale ? 16 : iconSize) + 'px'"
    [class.fullwidth]="d.fullWidth"
    (click)="select(d.refId, $event)"
  >
    <div
      style="
        margin: 0 2px 1px 2px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      "
    >
      <div *ngIf="showIcon && d.icon" style="align-self: center">
        <img
          class="abilityIcon"
          [style.width]="(d.ignoreIconScale ? 16 : iconSize) + 'px'"
          [style.height]="(d.ignoreIconScale ? 16 : iconSize) + 'px'"
          style="margin-top: -3px"
          [src]="d.icon"
        />
      </div>
      <div
        style="
          line-height: 16px;
          align-self: flex-start;
          padding-left: 4px;
          padding-top: 2px;
          white-space: pre-line;
        "
      >
        <span
          *ngIf="(showText || d.ignoreShowText) && d.text"
          style="white-space: pre-line"
          >{{ d.text }}</span
        >
        <span
          *ngIf="showTarget && d.targetIcon"
          style="margin-left: 2px; white-space: nowrap"
        >
          <span style="margin-left: 2px">&gt;</span>
          <img
            class="abilityIcon"
            style="margin-left: 2px; margin-top: -3px"
            [src]="d.targetIcon"
          />
        </span>
        <span
          class="usageOffset"
          *ngIf="showOffset && d.usageOffset && !d.clone && d.text"
        >
          ({{ d.usageOffset }})</span
        >
      </div>
    </div>
    <ng-template #tt>
      <span class="tooltip">{{ d.tooltip }}</span>
    </ng-template>
  </nz-tag>
</ng-container>
