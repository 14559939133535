<div mat-dialog-content style="height: 70px;">
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback [nzValidateStatus]="submitted && fightNameControl.errors && fightNameControl.errors.required?'error':''" nzErrorTip="You must enter a value">
        <input name="fightName" required nz-input placeholder="{{'dialog.fightSave.fightName' | translate}}" [formControl]="fightNameControl" [(ngModel)]="data.name" />
      </nz-form-control>
    </nz-form-item>
  </form>
  <div *nzModalFooter>
    <button nz-button (click)="onNoClick()">{{'dialog.cancel' | translate}}</button>
    <button nz-button nzType="primary" (click)="onSaveAsNewClick()" *ngIf="!!data.id" cdkFocusInitial>{{'dialog.saveAsNew' | translate}}</button>
    <button nz-button nzType="primary" (click)="onSaveClick()" cdkFocusInitial>{{'dialog.save' | translate}}</button>
  </div>
</div>

