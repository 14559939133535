<div class="sidepanel-container">
  <div class="sidepanel-full">
    <div>
      <span class="header">{{"component.downtime.phase" | translate}}</span>
    </div>
    <div>
      <i nz-icon nzType="clock-circle" theme="outline"></i><span class="time">{{from}}-{{to}}</span>
    </div>
    <div *ngIf="data.mode==='default'">
      <textarea nz-input placeholder="{{'component.downtime.leaveCommentHere' | translate}}" [(ngModel)]="comment"
        [nzAutosize]="{ minRows: 2, maxRows: 6 }" (ngModelChange)="showCommentButton = true;"></textarea>
    </div>
    <div *ngIf="data.mode==='table'">
      <div> {{comment}}</div>
    </div>
    <div *ngIf="showCommentButton && data.mode === 'default'">
      <button #commentButton nzType="primary" nz-button (click)="setComment();showCommentButton = false;">Set
        {{'component.downtime.comment' | translate}}</button>
    </div>
    <div *ngIf="data.mode ==='default'">
      <div>
        {{'component.downtime.clickToChangeColor' | translate}}
      </div>
    </div>
    <div>
      <div class="color-pikcer" [cpDisabled]="data.mode!=='default'" [(colorPicker)]="color" [cpOKButton]="true"
        cpDialogDisplay="inline" (colorPickerSelect)="setColor($event);" [style.background]="color">
      </div>
    </div>
    <div *ngIf="data.mode ==='default'">
      <button nz-button nzType="primary" (click)="remove(it)" nzDanger>{{'component.downtime.remove' | translate}}</button>
    </div>
  </div>
</div>
