export default [
  {
    date: "10.2.2021",
  },
  {
    date: "28.2.2021",
  },
  {
    date: "23.3.2021",
  },
  {
    date: "11.4.2021",
  },
  {
    date: "27.4.2021",
  },
  {
    date: "19.5.2021",
  },
  {
    date: "5.6.2021",
  },
  {
    date: "7.6.2021",
  },
  {
    date: "7.11.2021",
  },
  {
    date: "9.11.2021",
  },
  {
    date: "26.11.2021",
  },
  {
    date: "3.12.2021",
  },
  {
    date: "14.1.2022",
  },
  {
    date: "16.1.2022",
  },
  {
    date: "16.1.2022",
  },
  {
    date: "16.2.2022",
  },
  {
    date: "17.3.2022",
  },
  {
    date: "11.4.2022",
  },
  {
    date: "21.4.2022",
  },
  {
    date: "5.5.2022",
  },
  {
    date: "23.8.2022",
  },
  {
    date: "10.1.2023",
  },
  {
    date: "28.1.2023",
  },
  {
    date: "29.1.2023",
  },
  {
    date: "28.6.2024"
  },
  {
    date: "31.8.2024"
  }
];
