<form [formGroup]="mainForm"
      class="settings-tab">
  <div class="settings-container">
    <div class="settings-title">
      {{'dialog.settings.tabs.main.defaultFilters' | translate}}
    </div>
    <div class="settings-body">
      <settingsFilter #filter></settingsFilter>
    </div>
  </div>
  <div class="settings-container">
    <div class="settings-title">
      {{'dialog.settings.tabs.main.defaultView' | translate}}
    </div>
    <div class="settings-body">
      <settingsView #view></settingsView>
    </div>
  </div>
  <div class="settings-container">
    <div class="settings-title">
      {{'dialog.settings.tabs.main.caches' | translate}}
    </div>
    <div class="settings-body">
      <button nz-button
              [nzDanger]="true"
              (click)="onClearCachesClick()">{{'dialog.settings.tabs.main.clearCaches' | translate}}</button>
    </div>
  </div>
</form>
