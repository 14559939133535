<div mat-dialog-content style="height: 70px;">
  <form nz-form>
    <nz-form-item>
      <nz-form-control nzHasFeedback="submitted" [nzValidateStatus]="submitted && fightNameControl.errors && fightNameControl.errors.required?'error':'success'" nzErrorTip="You must enter a value">
        <input name="fightName"  required nz-input placeholder="{{'dialog.bossSave.fightName' | translate}}" [formControl]="fightNameControl" [(ngModel)]="data"/>
      </nz-form-control>
    </nz-form-item>
  </form>
</div>
<div *nzModalFooter>
  <button nz-button (click)="onNoClick()">{{'dialog.cancel' | translate}}</button>
  <button nz-button nzType="primary"  (click)="onSaveClick()" cdkFocusInitial>{{'dialog.save' | translate}}</button>
</div>
