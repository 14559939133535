<form [formGroup]="teamworkForm" class="settings-tab">
  <div class="settings-container">
    <div class="settings-title">
      {{'dialog.settings.tabs.teamwork.displayName' | translate}}:
    </div>
    <div class="settings-body">
      <input nz-input formControlName="displayName"
        placeholder="{{'dialog.settings.tabs.main.nameHere' | translate}}" />
    </div>
  </div>
</form>
