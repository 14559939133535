import { Injectable } from "@angular/core";
import { LocalStorageService } from "./LocalStorageService";
import { Observable } from "rxjs";
import * as D from "../dialogs";
import { NzModalService, NzModalState } from "ng-zorro-antd/modal";
import { IBoss, IBossAbility, IFight, IJobStats } from "../core/Models";
import { PresenterManager } from "../core/PresentationManager";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    private dialogs: NzModalService,
    private storage: LocalStorageService
  ) {}

  public get isAnyDialogOpened(): boolean {
    return this.dialogs.openModals.some(
      (m) => m.getState() === NzModalState.OPEN
    );
  }

  dialog: any;

  openLogin() {
    const dialogRef = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzContent: D.LoginDialog,
      nzTitle: "Login",
      nzWidth: 265,
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzCancelDisabled: true,
      nzFooter: null,
      nzMaskClosable: false,
    });
    dialogRef.afterClose.subscribe((result) => {
      if (result && result.signup) {
        setTimeout(() => {
          this.openRegister();
        });
        return;
      }
    });
  }

  openRegister(): Promise<any> {
    const dialogRef = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: "Register",
      nzContent: D.RegisterDialog,
      nzWidth: 355,
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzCancelDisabled: true,
      nzMaskClosable: false,
    });
    return this.toPromise(dialogRef.afterClose);
  }

  openBossAttackAddDialog(
    bossAbility: IBossAbility,
    presenter: PresenterManager,
    callBack: (b: any) => void
  ): void {
    const dialogRef = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: null,
      nzWidth: 700,
      nzClosable: false,
      nzMaskClosable: false,
      nzContent: D.BossAttackDialog,
      nzData: bossAbility
    });

    dialogRef.afterClose.subscribe((result) => {
      callBack(result);
    });
  }

  openCharacterDialog(
    jobStats: IJobStats,
    callBack: (b: { data: IJobStats }) => void
  ): void {
    const dialogRef = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: null,
      nzWidth: 450,
      nzClosable: false,
      nzMaskClosable: false,
      nzContent: D.CharacterDialogComponent,
      nzData: jobStats,
    });

    dialogRef.afterClose.subscribe((result) => {
      callBack(result);
    });
  }

  openLoad(): void {
    this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: "Load",
      nzContent: D.FightLoadDialog,
      nzWidth: 700,
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzCancelDisabled: true,
      nzFooter: null,
      nzMaskClosable: false,
    });
  }

  openImportFromFFLogs(
    code: string = null,
    noRedirect?: boolean
  ): Promise<any> {
    const dialogRef = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: "Import from FFLogs",
      nzWidth: 700,
      nzClosable: false,
      nzContent: D.FFLogsImportDialog,
      nzData: {
        code,
        noRedirect,
      },
    });

    return this.toPromise(dialogRef.afterClose);
  }

  openSaveBoss(name: string): Promise<any> {
    const dialogRef = this.dialogs.create({
      nzTitle: "Save",
      nzContent: D.BossSaveDialog,
      nzWidth: 700,
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzCancelDisabled: true,
      nzFooter: null,
      nzMaskClosable: false,
      nzData: name,
    });
    return this.toPromise(dialogRef.afterClose);
  }

  openSaveFight(dataFn: () => any): Promise<IFight> {
    const dialogref = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: "Save",
      nzContent: D.FightSaveDialog,
      nzWidth: 700,
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzCancelDisabled: true,
      nzFooter: null,
      nzMaskClosable: false,
      nzData: dataFn(),
    });
    return this.toPromise(dialogref.afterClose);
  }

  private toPromise<T>(obs: Observable<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      obs.subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  openHelp(): Promise<void> {
    const promise = new Promise<void>((resolve) => {
      const dialogRef = this.dialogs.create({
        nzWrapClassName: "vertical-center-modal",
        nzTitle: "Help",
        nzContent: D.HelpDialog,
        nzWidth: "80%",
        nzClosable: false,
        nzKeyboard: false,
        nzMaskClosable: false,
      });
      dialogRef.afterClose.subscribe(() => {
        this.storage.setString("help_shown", "yes");
        resolve();
      });
    });

    return promise;
  }

  openSettings(): void {
    this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzTitle: null,
      nzClassName: "settingsWindow",
      nzContent: D.SettingsDialog,
      nzWidth: "900px",
      nzClosable: false,
    });
  }

  executeWithLoading(text: string, action: (ref: { close(): void }) => void) {
    let loadingDialogRef: any;
    setTimeout(() => {
      loadingDialogRef = this.dialogs.create({
        nzWrapClassName: "vertical-center-modal",
        nzContent: D.LoadingDialog,
        nzTitle: null,
        nzWidth: 150,
        nzClosable: false,
        nzKeyboard: false,
        nzOkDisabled: true,
        nzCancelDisabled: true,
        nzFooter: null,
        nzMaskClosable: false,
        nzData: text,
      });

      loadingDialogRef.afterOpen.subscribe(() => {
        action({ close: () => loadingDialogRef.destroy() });
      });
    });
  }

  openTable() {
    this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzContent: D.TableViewDialog,
      nzTitle: null,
      nzWidth: "99%",
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzMaskClosable: false,
      nzData: {},
    });
  }

  openBossTemplates(needSave: boolean, boss?: IBoss) {
    this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzContent: D.BossTemplatesDialog,
      nzTitle: null,
      nzWidth: "90%",
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzMaskClosable: false,
      nzData: {
        needSave,
        boss,
      },
    });
  }

  openWhatsNew(change?: any, notes?: any): Promise<any> {
    const changes = change || notes;
    const ref = this.dialogs.create({
      nzWrapClassName: "vertical-center-modal",
      nzContent: D.WhatsNewDialog,
      nzTitle: "What's new",
      nzWidth: "90%",
      nzClosable: false,
      nzKeyboard: false,
      nzOkDisabled: true,
      nzMaskClosable: false,
      nzData: changes,
    });
    return this.toPromise(ref.afterClose);
  }
}
