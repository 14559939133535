<button nz-button nz-popover (click)="change()" (nzPopoverVisibleChange)="change()" nzPopoverTrigger="click"
  nzPopoverPlacement="bottomLeft" [nzPopoverContent]="contentTemplate">
  <span>
    <i nz-icon nzType="filter" theme="twotone"></i>
    {{"fightline.toolbar.filter._" | translate}}
  </span>
</button>

<ng-template #contentTemplate>
  <div nz-row style="width: 560px;" class="noselect">
    <div nz-col nzSpan="12">
      <div>
        <label nz-checkbox (ngModelChange)="checkAllFunc($event)" [(ngModel)]="checkAll"
          [nzIndeterminate]="checkAll === undefined"><strong>{{"fightline.toolbar.filter.abilityTypes" |
            translate}}</strong></label>

      </div>
      <ul nz-menu>
        <li nz-menu-item *ngFor="let f of filters">
          <label nz-checkbox (ngModelChange)="updateFilter('ability', f.name, $event)"
            [(ngModel)]="presenterManager.filter.abilities[f.name]">{{f.desc}}</label>
        </li>
      </ul>
    </div>
    <div nz-col nzSpan="12" style="padding: 5px;">
      <div>
        <div>
          <strong>{{"fightline.toolbar.filter.bossAttackTags" | translate}}</strong>
        </div>
        <nz-tag *ngFor="let tag of tags" nzMode="checkable" [(nzChecked)]="tag.checked"
          (nzCheckedChange)="updateFilter('boss')">{{tag.text}}</nz-tag>
      </div>
      <div>
        <div *ngIf="sources && sources.length">
          <strong>{{"fightline.toolbar.filter.bossAttackSources" | translate}}</strong>
        </div>
        <nz-tag *ngFor="let source of sources" nzMode="checkable" [(nzChecked)]="source.checked"
          (nzCheckedChange)="updateFilter('boss')">{{source.text}}</nz-tag>
      </div>
      <div style="margin-top: 8px;">
        <div>
          <strong>{{"fightline.toolbar.filter.bossAttackDamageType" | translate}}</strong>
        </div>
        <nz-tag nzMode="checkable" [(nzChecked)]="presenterManager.filter.attacks.isMagical"
          (nzCheckedChange)="updateFilter('boss')">{{"damageType.magical" | translate}}</nz-tag>
        <nz-tag nzMode="checkable" [(nzChecked)]="presenterManager.filter.attacks.isPhysical"
          (nzCheckedChange)="updateFilter('boss')">{{"damageType.physical" | translate}}</nz-tag>
        <nz-tag nzMode="checkable" [(nzChecked)]="presenterManager.filter.attacks.isUnaspected"
          (nzCheckedChange)="updateFilter('boss')">{{"damageType.unaspected" | translate}}</nz-tag>
      </div>
      <div style="margin-top: 8px;">
        <div>
          <strong>{{"fightline.toolbar.filter.fflogsAttackSource" | translate}}</strong>
        </div>
        <nz-switch [ngModel]="true" nzCheckedChildren="{{'attackSource.cast' | translate}}"
          nzUnCheckedChildren="{{'attackSource.damage' | translate}}" [(ngModel)]="presenterManager.fflogsSource"
          (ngModelChange)="fflogsSourceChanged($event)">></nz-switch>
      </div>
      <div style="margin-top: 8px;">
        <div>
          <strong>{{"fightline.toolbar.filter.fightLevel" | translate}}</strong>
        </div>
        <nz-select [(ngModel)]="presenterManager.fightLevel" (ngModelChange)="levelChanged($event)">
          <nz-option *ngFor="let l of levels" [nzLabel]="''+l" [nzValue]="l"></nz-option>
        </nz-select>
      </div>
      <div style="margin-top: 8px;">
        <div>
          <strong>{{"fightline.toolbar.filter.presets" | translate}}</strong>
        </div>
        <nz-select nzShowSearch [nzDropdownRender]="renderTemplate" nzAllowClear [(ngModel)]="presenterManager.selectedPreset"
          [nzOpen]="openedSelect" (ngModelChange)="onPresetChanged($event)"
          nzPlaceHolder="{{'fightline.toolbar.filter.selectPreset' | translate}}" #presetSelect>
          <nz-option nzLabel="" nzValue=""></nz-option>
          <nz-option nzCustomContent *ngFor="let item of presenterManager.presets | keyvalue" [nzLabel]="item.key" [nzValue]="item.key">
            {{item.key}}
          </nz-option>
        </nz-select>
        <ng-template #renderTemplate>
          <nz-divider></nz-divider>
          <div class="container">
            <input nz-input nzSize="small" required [value]="presenterManager.selectedPreset || ''" type="text"
              #inputElement />
            <a nz-button [disabled]="!inputElement.value" class="add-item"
              (click)="savePreset(inputElement.value);openedSelect = false;">
              <span *ngIf="!inputElement.value || presenterManager.selectedPreset !== inputElement.value">
                {{"fightline.toolbar.filter.savePreset" | translate}}
              </span>
              <span *ngIf="inputElement.value && presenterManager.selectedPreset === inputElement.value">
                {{"fightline.toolbar.filter.updatePreset" | translate}}
              </span>
            </a>
          </div>
        </ng-template>
        <div *ngIf="presenterManager.selectedPreset">
          <a (click)="attachPreset()">Attach preset to timeline</a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
